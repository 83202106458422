<template>
    <div v-if="isModalLoaded" class="staff-month__settings settings settings__admin">
        <button type="button" class="btn-block-option close-modal" @click="$modal.hide('AdminModal')"> ×
        </button>
        <div class="settings__admin">
            <header class="settings__header">
                <div class="settings__meta">
                    <div class="settings__header-wrap">
                        <div class="settings__header-row">
                            <div class="settings__header-col">
                                <h2 class="h2 settings__title">Сотрудники месяца</h2>
                            </div>

                            <div class="settings__header-col">
                                <div class="settings__header-months">
                                    <a
                                        href="javascript:void(0)"
                                        class="settings__header-month"
                                        :class="{'settings__header-month--active':isCurrentMonth}"
                                        @click="isCurrentMonth = true"
                                    >
                                        {{MONTHS_NAMES[currentMonth]}}
                                    </a>
                                    <a
                                        href="javascript:void(0)"
                                        class="settings__header-month"
                                        :class="{'settings__header-month--active':!isCurrentMonth}"
                                        @click="isCurrentMonth = false"
                                    >
                                        {{MONTHS_NAMES[prevMonth]}}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <span class="settings__max hidden">
                            Максимум 1 сотрудник
                        </span>
                    </div>

                    <div class="settings__header-tabs"></div>
                </div>
            </header>

            <main class="settings__body settings__body--desktop">
                <div class="settings__block settings__staff">
                    <div class="settings__subheader">
                        <div class="settings__subheader-tabs">
                            <div
                                class="settings__subheader-tab"
                                :class="{ 'settings__subheader-tab--active':currentTab === 1 }"
                                @click="onSwitch(1)"
                            >
                                <h3 class="h3 settings__subheader-title">
                                    Предложения
                                    {{ recommendationList[month].users.length }}
                                </h3>
                            </div>

                            <div
                                class="settings__subheader-tab"
                                :class="{ 'settings__subheader-tab--active':currentTab === 2 }"
                                @click="onSwitch(2)"
                            >
                                <h3 class="h3 settings__subheader-title">
                                    Все сотрудники
                                    {{ allStaff.length }}
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="settings__block-wrapper">
                        <div class="settings__search">
                            <input
                                type="search"
                                placeholder="Поиск"
                                class="settings__search-input"
                                v-model="searchValue"
                            >
                        </div>

                        <!-- Список сотрудников в выбранной вкладке-->
                        <ul
                            v-if="currentTab === 1"
                            class="settings__list"
                        >
                            <span
                                v-if="!recommendationList[month].users.length"
                                class="settings__list-empty"
                            >
                                Нет предложенных сотрудников месяца.
                            </span>

                            <li
                                v-for="user in recommendationListSorted"
                                class="settings__user"
                                :class="{'settings__user--selected': selectedUsersId.includes(user.id)}"
                            >
                                <a href="javascript:void(0)">
                                    <avatar :src="user.image_zoom_out"/>
                                </a>

                                <div class="settings__user-info">
                                    <router-link :to="user.link" class="settings__user-title">{{user.short_name}}</router-link>
                                    <div class="settings__user-merits">
                                        <span v-for="merit in user.merits" class="settings__user-desc">
                                            <router-link :to="merit.author.link"
                                               class="settings__user-meritauthor">{{merit.author.short_name}}</router-link> - “{{merit.merit || 'Заслуга не указана'}}”
                                        </span>
                                    </div>
                                    <div class="settings__user-about">
                                        <span class="settings__user-position">{{user.positions}}</span>
                                        <span
                                            v-for="struct in user.structure"
                                            class="settings__user-structs"
                                        >
                                            {{struct.name}}
                                        </span>
                                    </div>
                                </div>
                                <button
                                    :disabled="selectedUsersId.includes(user.id)"
                                    class="settings__user-btn settings__user-add"
                                    title="Добавить"
                                    @click="addUser(user)"
                                />
                            </li>
                        </ul>
                        <ul
                            v-else-if="currentTab === 2"
                            class="settings__list"
                        >
                            <span v-if="!allStaff.length" class="settings__list-empty">
                                На портале пока нет сотрудников.
                            </span>
                            <li
                                v-for="user in allStaffSorted"
                                class="settings__user"
                                :class="{'settings__user--selected': selectedUsersId.includes(user.id)}"
                            >
                                <div class="settings__user-info ml-0">
                                    <router-link
                                        :to="user.link"
                                        class="settings__user-title"
                                    >
                                        {{user.short_name}}
                                    </router-link>
                                </div>
                                <button :disabled="selectedUsersId.includes(user.id)"
                                        class="settings__user-btn settings__user-add" title="Добавить"
                                        @click="addUser(user)"/>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="settings__block settings__selected">
                    <div class="settings__subheader">
                        <div class="settings__subheader-tabs">
                            <div class="settings__subheader-tab settings__subheader-tab--active">
                                <h3 class="h3 settings__subheader-title">Выбрано
                                    {{selectedList[month].users.length}}</h3>
                            </div>
                        </div>
                    </div>
                    <ul class="settings__list settings__selected-list">
                        <selected-user
                            v-for="user in selectedList[month].users"
                            :key="month+user.id"
                            :user="user"
                            :on-save="saveMerit"
                            :on-remove="removeUser"
                        />
                    </ul>
                </div>
            </main>

            <!-- Слайдер для мобильных устройств -->
            <main class="settings__body settings__body--mobile">
                <swiper class="settings__slider" :options="swiperOptions">
                    <swiper-slide class="settings__slider-item">
                        <h3 @click="currentTab = 1" class="h3 settings__slider-link" :class="{'settings__slider-link--active':currentTab === 1}">Предложения {{recommendationList[month].users.length}}</h3>
                    </swiper-slide>
                    <swiper-slide class="settings__slider-item">
                        <h3 @click="currentTab = 2" class="h3 settings__slider-link" :class="{'settings__slider-link--active':currentTab === 2}">Все сотрудники {{allStaff.length}}</h3>
                    </swiper-slide>
                    <swiper-slide class="settings__slider-item">
                        <h3 @click="currentTab = 3" class="h3 settings__slider-link" :class="{'settings__slider-link--active':currentTab === 3}">Выбрано
                            {{selectedList[month].users.length}}</h3>
                    </swiper-slide>
                </swiper>
                <div v-if="currentTab !== 3" class="settings__search">
                    <input type="search" v-model="searchValue" class="settings__search-input"
                           placeholder="Поиск">
                </div>
                <div class="settings__slider-wrap">
                    <ul
                        v-if="currentTab === 1"
                        class="settings__list"
                    >
                        <span v-if="!recommendationList[month].users.length" class="settings__list-empty">
                                Нет предложенных сотрудников месяца.
                            </span>
                        <li
                            v-for="user in recommendationListSorted"
                            class="settings__user"
                            :class="{'settings__user--selected': selectedUsersId.includes(user.id)}"
                        >
                            <a href="javascript:void(0)">
                                <avatar :src="user.image_zoom_out"/>
                            </a>
                            <div class="settings__user-info">
                                <router-link :to="user.link" class="settings__user-title">{{user.short_name}}</router-link>
                                <div class="settings__user-merits">
                                        <span v-for="merit in user.merits" class="settings__user-desc">
                                            <router-link :to="merit.author.link"
                                               class="settings__user-meritauthor">{{merit.author.short_name}}</router-link> - “{{merit.merit || 'Заслуга не указана'}}”
                                        </span>
                                </div>
                                <div class="settings__user-about">
                                    <span class="settings__user-position">{{user.positions}}</span>
                                    <span
                                        v-for="struct in user.structure"
                                        class="settings__user-structs"
                                    >
                                            {{struct.name}}
                                        </span>
                                </div>
                            </div>
                            <button
                                :disabled="selectedUsersId.includes(user.id)"
                                class="settings__user-btn settings__user-add"
                                title="Добавить"
                                @click="addUser(user)"
                            />
                        </li>
                    </ul>
                    <ul
                        v-else-if="currentTab === 2"
                        class="settings__list"
                    >
                        <span v-if="!allStaff.length" class="settings__list-empty">
                            На портале пока нет сотрудников.
                         </span>
                        <li
                            v-for="user in allStaffSorted"
                            class="settings__user"
                            :class="{'settings__user--selected': selectedUsersId.includes(user.id)}"
                        >
                            <div class="settings__user-info ml-0">
                                <router-link
                                    :to="user.link"
                                    class="settings__user-title"
                                >
                                    {{user.short_name}}
                                </router-link>
                            </div>
                            <button :disabled="selectedUsersId.includes(user.id)"
                                    class="settings__user-btn settings__user-add" title="Добавить"
                                    @click="addUser(user)"/>
                        </li>
                    </ul>
                    <ul v-if="currentTab === 3" class="settings__list settings__selected-list">
                        <selected-user
                            v-for="user in selectedList[month].users"
                            :key="month+user.id"
                            :user="user"
                            :on-save="saveMerit"
                            :on-remove="removeUser"
                        />
                    </ul>
                </div>
            </main>

            <footer class="settings__btns">
                <button class="v-btn v-btn--transparent" @click="$modal.hide('AdminModal')">Отменить</button>
                <button class="v-btn" @click="saveUsers" :disabled="this.isSaving">Сохранить</button>
            </footer>
        </div>

        <div class="settings__chief">

        </div>

    </div>
</template>

<script>
    import {mapState} from "vuex"
    import session from '@/api/session';

    export default {
        name: "AdminModal",
        components: {
            Avatar: () => import('@/components/Avatar'),
            SelectedUser : () => import('./SelectedUser'),
        },
        data() {
            return {
                currentMonth: 1,
                prevMonth: 1,
                selectedList: {
                    currentMonth: {
                        number: 0,
                        users: []
                    },
                    prevMonth: {
                        number: 0,
                        users: []
                    }
                },
                searchValue: this.searchValue || '',
                currentTab: 2,
                recommendationList: {
                    currentMonth: {
                        number: 0,
                        users: []
                    },
                    prevMonth: {
                        number: 0,
                        users: []
                    }
                },
                isCurrentMonth: false,
                isModalLoaded: false,
                isSaving: false,
                swiperOptions: {
                    slidesPerView: 'auto',
                    containerModifierClass: 'settings__slider-',
                    slideClass: 'settings__slide',
                    slideActiveClass: 'settings__slide--active'
                },
            }
        },
        async created() {
            let month = new Date().getMonth();
            this.currentMonth = month + 1;
            if (month === 0) {
                this.prevMonth = 12
            } else {
                this.prevMonth = month
            }
            this.selectedList.currentMonth.number = this.currentMonth;
            this.selectedList.prevMonth.number = this.prevMonth;
            this.recommendationList.currentMonth.number = this.currentMonth;
            this.recommendationList.prevMonth.number = this.prevMonth;

            try {
                await this.$store.dispatch('staff_month/setAllStaff');
                await this.$store.dispatch('staff_month/setRecommendationAdmin');
                await this.$store.dispatch('staff_month/setRecommendationStaff');
                /* Список рекомендаций от начальников для админа */
                if (this.recommendationAdmin.length) {
                    this.recommendationAdmin.forEach(year => {
                        year.months.forEach(month => {
                            let isCurrentMonth = month.month === this.currentMonth;
                            let selectedMonth = this.recommendationList[isCurrentMonth ? 'currentMonth' : 'prevMonth'];
                            month.interim.forEach(user => {
                                selectedMonth.users.push(user);
                            })
                        });
                    });
                }
                /* Список сохранённых сотрудников месяца */
                if (this.recommendationStaff.length) {
                    this.recommendationStaff.forEach(year => {
                        year.months.forEach(month => {
                            let isCurrentMonth = month.month === this.currentMonth;
                            let selectedMonth = this.selectedList[isCurrentMonth ? 'currentMonth' : 'prevMonth'];
                            selectedMonth.users.push(...month.interim)
                        });
                    })
                }
                this.isModalLoaded = true;
            } catch(error) {
                this.toastError('Произошла непредвиденная ошибка на сервере. Обратитесь к администрации');
                this.$modal.hide('AdminModal');
                console.error(error);
            }
        },
        computed: {
            month() {
                return this.isCurrentMonth ? 'currentMonth' : 'prevMonth';
            },
            selectedUsersId() {
                const idList = [];
                this.selectedList[this.month].users.map(user => {
                    idList.push(user.id);
                });
                return idList
            },
            recommendationListSorted() {
                const list = this.recommendationList[this.month].users;
                return this.searchValue.length ? list.filter(user => user.full_name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1) : list
            },
            allStaffSorted() {
                return this.searchValue.length ? this.allStaff.filter(item => item.full_name.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1) : this.allStaff
            },
            ...mapState('staff_month', [
                'allStaff', 'recommendationAdmin', 'recommendationStaff'
            ]),
            ...mapState('default_data', [
                'current_user', 'MONTHS_NAMES'
            ])
        },
        methods: {
            onSwitch(tab) {
                this.searchValue = '';
                this.currentTab = tab;
            },
            addUser(user) {
                this.selectedList[this.month].users.push(user);
            },
            removeUser(user) {
                let users = this.selectedList[this.month].users;
                this.selectedList[this.month].users = users.filter(structUser => structUser.id !== user.id)
            },
            saveMerit(userId, merit = '') {
                const user = this.selectedList[this.month].users.find(user => user.id === userId);
                user.merit = merit;
            },
            async saveUsers() {
                /* К текущему месяцу прибавляем 1, так как в Python месяца начинабтся с 1
                * А в JS с нуля */
                this.isSaving = true;
                let date = new Date();
                try {
                    let data = [
                        {
                            year: date.getFullYear(),
                            months: [
                                {
                                    //Текущий месяц
                                    number: this.currentMonth,
                                    users: this.selectedList.currentMonth.users.map(user => {
                                        if (!user.merit) {
                                            this.toastError('Укажите заслуги у всех выбранных сотрудников');
                                            throw new Error();
                                        }
                                        return {
                                            id: user.id,
                                            merit: user.merit
                                        }
                                    })
                                },
                            ]
                        }
                    ]
                    let prevMonthData = { //Предыдущий месяц
                        number: this.prevMonth,
                        users: this.selectedList.prevMonth.users.map(user => {
                            if (!user.merit) {
                                this.toastError('Укажите заслуги у всех выбранных сотрудников');
                                throw new Error();
                            }
                            return {
                                id: user.id,
                                merit: user.merit
                            }
                        })
                    };
                    if (this.currentMonth === 1) {
                        data.push({
                            year: date.getFullYear() - 1,
                            months: [prevMonthData]
                        })
                    } else {
                        data[0].months.push(prevMonthData)
                    }

                    await session.post(`/api/v1/employees-month/`, data);
                    this.$store.dispatch('staff_month/setStaffMonth');
                    this.$modal.hide('AdminModal');
                } catch (error) {
                    this.isSaving = false;
                    console.error(error)
                }
            },
            toastError(text, timer = 3000) {
                this.$swal.fire({
                    toast: true,
                    position: 'top-end',
                    title: text,
                    icon: "error",
                    showConfirmButton: false,
                    timer: timer,
                    timerProgressBar: true,
                    onOpen: (toast) => {
                        toast.addEventListener('mouseenter', this.$swal.stopTimer);
                        toast.addEventListener('mouseleave', this.$swal.resumeTimer);
                    }
                });
            }
        }
    }
</script>

<style lang="scss">
    @import "#sass/dashboard/staff-month_settings";

    .v--modal-overlay[data-modal="AdminModal"] {
        .vm--modal {
            max-width: 900px !important;
            margin: 0 auto;
        }
    }

    @media (max-width: 500px) {
        .settings {
            .close-modal {
                display: none !important;
            }

            &__admin {
                .settings__slider {
                    padding: 0 10px;
                }
            }
        }
    }
</style>
